.splash-intro {
    flex-direction: column;
    -webkit-appearance: none;
}

a:hover {
    text-decoration: none;
    color: #191919;
    text-underline: none;
}

p {
    text-align: left;
    margin: 0;
}

.row {
    flex: none;
    margin: 0;
}

.col {
    padding: 0;
    min-width: 200px;
}

.col-sm {

}

.profile-item {
    padding-bottom: 5px;
}

.role {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
}

.name {
    font-size: 56px;
    font-weight: 700;
}

.blurb {
    color: #BDBDBD;
    font-size: 14px;
    font-weight: 500;
}

.pfp-holder {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 50px;
}

.pfp {
    width: 50vw;
    height: 50vw;
    max-height: 200px;
    max-width: 200px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../images/1.jpg");
}

.resume-btn-holder {
    -webkit-appearance: none;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    min-width: 200px;
    padding: 0;
}

.socials-holder {
    text-align: left;
    transform: translateX(-10px);
}

.profile-descrip, .resume-btn-holder, .socials-holder {
    margin-bottom: 20px;
}

.copyright-text{
    font-size: 14px;
}

@media screen and (min-width: 600px) {
    .row {
        padding-bottom: 20px;
        display: flex;
    }

    .profile-item {

    }

    .pfp-holder, .resume-btn-holder {
        max-width: 200px;
        margin-bottom: 15px;
    }

    .profile-descrip, .socials-holder {
        padding-left: 50px;
    }

    .profile-descrip {

    }

    .col-sm {
        width: 200px;
    }

    .pfp {
        width: 20vw;
        height: 20vw;
        max-height: 200px;
    }

    .resume-btn-holder{
        justify-content: center;
        display: block;
    }

    .role{
    }

    .name{
        font-size: 6.25vw;
        font-weight: 700;
    }

    .blurb {
        font-size: 15px;
    }

    .socials-holder {
        transform: translateX(-10px);
        text-align: left;

        display: flex;
        align-items: center;
    }

    .copyright-text{
        font-size: 14px;
    }

}
