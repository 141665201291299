
.modal-link-btn {
    text-transform: uppercase;
    transition: all 250ms ease-in-out;
    text-decoration: none;

    font-weight: 600;
    letter-spacing: 0.03em;
    border-radius: 40px;
    border: 2px solid;
    color: #F2F2F2;

    padding: 2% 8%;
    font-size: 14px;
    margin-bottom: 15px;
}

.modal-link-btn:hover {
    transform: scale(1.05);
    font-weight: 700;
    color: #191919;
}

.main-btn {
    padding: 2% 15%;
    color: #5ED9CE;
}

.main-btn:hover{
    background: #5ED9CE;
    border-color: #5ED9CE;
}

.other-btn {
    color: #F2F2F2;
}

.other-btn:hover {
    background: #F2F2F2;
    border-color: #F2F2F2;
}

@media screen and (min-width: 576px) {
    .main-btn {
        padding: 3px 20px;
    }

    .modal-link-btn {
        max-width: 150px;
        font-size: 0.8empx;
        border-radius: 40px;
        margin-bottom: 2.5%;
    }

    .other-btn {
        padding: 3px 15px;
    }
}