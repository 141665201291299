.resume-btn, .resume-btn a{
    transition: all 250ms ease-in-out;
    background: transparent;
    color: #5ED9CE;
    border-color: #5ED9CE;
}

.resume-btn:hover {
    border-color: #5ED9CE;
    transform: scale(1.08);
    background: #5ED9CE;
    color: #191919;
}

.portfolio-btn, .portfolio-btn a{
    transition: all 250ms ease-in-out;
    background: transparent;
    color: #f2f2f2;
    border-color: #f2f2f2;
}

.portfolio-btn:hover {
    border-color: #f2f2f2;
    transform: scale(1.08);
    background: #f2f2f2;
    color: #191919;
}

.resume-btn {
    max-width: 200px;
    text-decoration: none;
    text-transform: uppercase;
    /** color: #191919;
    background: #5ED9CE;
    **/

    padding: 5px;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 40px;
    border: 3px solid;
    width: 60%;
    margin: 5px 5px;
}

.portfolio-btn {
    max-width: 200px;
    text-decoration: none;
    text-transform: uppercase;

    padding: 2px 5px;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 40px;
    border: 3px solid;
    width: 60%;
    margin: 5px 5px;
}

.resume-btn a{
    color: #f2f2f2;
}

.portfolio-btn a{
    color: #f2f2f2;
}

@media screen and (min-width: 576px) {
    .resume-btn, .portfolio-btn {
/**        max-width: 250px; **/
        width: 20vw;
        font-size: 16px;
        padding: 10px 30px;
        border-radius: 40px;
        margin: 5px 5px;
    }

    .portfolio-btn {
        padding: 2px 10px;
    }


}