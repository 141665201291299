.App {
  text-align: center;
  padding: 40px 20px 20px;
  background: linear-gradient(180deg, rgba(94, 217, 206, 0.5) 0%, rgba(255, 255, 255, 0) 20%), #191919;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F2F2F2;
}

.App-link {
  color: #5ED9CE;
}



@media screen and (min-width: 641px) {
  .App {
    padding: 8% 17.5% 5%;
  }

}

@media screen and (min-width: 1281px) {
  .App {
    padding: 10% 20% 5%;
  }

}