.footer {
    padding: 40px 20px;
    text-align: center;
    border-top: 1px solid #333;
}

.footer ul{
    list-style-type: none;
    margin: 0;
}

.contact-nav-wrapper {
    width: 100%;
}

.nav {
    display: flex;
    justify-content: flex-end;
    align-content: center;
}

.nav *, .email-link a{
    transition: all 200ms ease-in-out;
    color: #F2F2F2;
}

.nav *:hover, .email-link a:hover{
    color: #5ED9CE;
}

.nav * {
    padding: 10px 0;
}

.contact, .resume-copyright-wrapper {
    padding: 20px 0;
}

.contact {
    text-align: center;
}

.contact .socials{
    transform: none;
}

.email-link {
    padding: 20px 0;
}

.footer .contact{
    flex: none;
    max-width: 100%;
}

.footer .resume-btn {
    display: none;
}

.resume-copyright-wrapper {
    width: 100%;
    display: block;
    text-transform: uppercase
}

.resume-copyright-wrapper *{
    text-align: center;
}

.copyright-text {
    padding: 0;
}

.nav {
    text-transform: uppercase;
    text-align: right;
    display: none;
}

@media screen and (min-width: 576px) {
    .footer {
        padding: 8% 20%;
        transform: inherit;
    }

    .contact {
        text-align: left;
        max-width: 50%;
    }

    .contact .socials {
        transform: translateX(-10px)
    }

    .footer .contact{
        flex: 0 0 50%
    }

    .nav {
        display: block;
    }

    .footer .resume-btn {
        display: inline-block;
    }

    .copyright-text {
        padding-top: 20px;
    }
}