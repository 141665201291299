
.descrip-text-inline {
    display: inline;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}
.descrip-image-wrapper{
    padding: 20px 0;
}

.descrip-image {
    border-radius: 2px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    min-height: 200px;
}

.yt-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin: 10px 0 10px;

}
.yt-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.imgur-container{
    position: relative;
    width: 100%;

}

.imgur-gallery {

}
.embed-container{
    width: 100%;

}

.embed-container iframe{
    border: None;
    padding:0;
    min-height: 400px;
    width: 100%;

}

@media screen and (min-width: 576px) {
    .descrip-text {

    }

    .descrip-image-wrapper {
    }

    .descrip-image {
        min-height: 250px;
    }

}