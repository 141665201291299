.socials * {
    transition: all 250ms ease-in-out;
}

.socials {
    text-align: left;
    display: inline-flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.social-object {
    font-size: 18px;
    text-decoration: none;
    margin: 5px;
}

.social-object:hover {
    transform: scale(1.1);
}


.social-object:hover .icon-background{
    color: #5ED9CE;
}

.social-icon{
    color: #191919;
}

.social-icon:hover{
    color: #191919;
}

.icon-background:hover {

}

.icon-background{
    color: #F2F2F2;
}

@media screen and (min-width: 576px) {

    .social-object {
        font-size: 20px;
        margin: 5px;
    }
}
