.other-tag{

}

.hardware-tag{
    color: #F19A3E;
}

.eh-tag{
    color: #009bd3;
}

.python-tag, .java-tag {
    color: #EB5E55;
}


.design-tag {
    color: #5ED9CE;
}

.webdev-tag{
    color: #97DB4F;
}

.cad-tag{
    color: #a8ecbe;
}

.leadership-tag {
    color: #ffd449;
}

.tags {
    display: inline-flex;
    bottom: 0;
    position: absolute;
}

.tags *{
    letter-spacing: 0.02em;
    border-radius: 30px;
    text-transform: uppercase;
    border: 1px solid;
    font-size: 9px;
    font-weight: 500;
    margin-right: 10px;
    padding: 3px 12px;
    -webkit-appearance: none;
}



@media screen and (min-width: 576px) {
    .tags * {
        min-width: 30px;
        max-width: 150px;
        margin-right: 10px;
        font-size: 10px;
    }


}