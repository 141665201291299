.topic-card-container {
    margin-right: 20px;
}

.topic-header {
    color: white;
    font-size: 28px;
    font-weight: 700;
    border-bottom: 1px solid #333333;
}

.topic-wrapper {
    text-align: left;
    padding: 20px 0;
}

.card-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    padding: 5% 0;
}

.card-wrapper::-webkit-scrollbar {
    display: none;
}

#highlights .seemore-btn{
    display: None;
}

.seemore-btn {
    display: None;
}

.seemore-btn, button{
    transition: all 250ms ease-in-out;
    background: transparent;
    text-align: center;
    color: #f2f2f2;
    border-color: #f2f2f2;
}

.seemore-btn button:hover {
    border-color: #f2f2f2;
    transform: scale(1.08);
    background: #f2f2f2;
    color: #191919;
}


.seemore-btn button{

    color: #f2f2f2;

    text-decoration: none;
    text-transform: uppercase;
    /** color: #191919;
    background: #5ED9CE;
    **/

    padding: 3px;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 40px;
    border: 3px solid;
    width: 60%;
}


@media screen and (min-width: 576px) {
    .topic-wrapper{
        padding: 15px 0;
    }

    .topic-card-container {
        display: inline-flex;

        min-width: 200px;
        max-width: 30%;
        min-height: 300px;
        margin: 1.5%;
    }

    .card-wrapper {
        justify-content: center;
        flex-wrap: wrap;
        padding: 2.5% 0 5%;
    }

    .seemore-btn {
        display: block;
        font-size: 14px;
        padding: 10px 30px;
        border-radius: 40px;
    }

    .seemore-btn button{
        max-width: 200px;
    }

    #highlights .seemore-btn{
        display: None;
    }
}

@media screen and (min-width: 1650px) {
    .card-wrapper {
        padding: 2.5% 10% 5%;
    }
}