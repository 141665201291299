
.topic-card {
    justify-content: start;
    align-items: start;
    text-align: left;

    border-radius: 5px;
    background-color: #222222;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    color: #f2f2f2;

    padding: 10px;
    width: 50vw;
    height: 80vw;
}

.topic-card:hover{
    background-color: #262626;
    transform: scale(1.03);

}

.thumbnail {
    min-height: 50px;
    min-width: 50px;
    height: 60%;

}

.thumbnail-inner {
    border-radius: 2px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
}

.topic-info {
    position: relative;
    height: 40%;
}


.topic-heading-info {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 5px 0;
}

.topic-heading-info *{
    padding: 2.5% 0;
}

.title {
    font-size: 1em;
    font-weight: 600;

}

.tagline {
    color: #BDBDBD;
    font-size: 0.7em;
}

.tags-wrapper{
    position: absolute;
    bottom: 0;
}

@media screen and (min-width: 576px) {
    .topic-heading-info {
        padding-top: 10px;
    }

    .title {
        font-size: 16px;
    }

    .tagline{
        font-size: 12px;
    }

    .topic-card {
        max-width: 220px;
        min-height: 360px;
        padding: 6%;
        height: 100%;
    }

}