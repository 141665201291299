
body.swal2-shown > [aria-hidden="true"] {
    filter: blur(5px);
}

body > * {
    transition: 150ms filter linear;
}

.swal2-content{
    padding: 0;
}

.thumbnail-info-wrapper {
    flex-direction: column;
    border-bottom: 2px solid #222222;
}

.modal-container {
    color: #F2F2F2;
    text-align: left;
}

.modal-container .tags {
    position: relative;
}

.topic-cover {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.cover-inner {
    border-radius: 2px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    box-shadow: 0 4px 168px 10px rgba(0, 0, 0, 0.25);

    height: 50vw;
    width: 50vw;
}

.topic-header-info {
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.topic-header-info-inner {
    width: 100%;
}

.section-text, .name-tagline-wrapper, .timeline, .topic-links, .content-description, .tags{
    padding-top: 20px;
}

.content-description pre{
    color: #F2F2F2;
}

.section-text{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
}

.name-tagline-wrapper {
    padding-top: 30px;
}

.topic-name{
    font-weight: 700;
    font-size: 30px;
}

.tagline-text, .timeline {
    font-size: 14px;
}

.tagline-text {
    padding-top: 5px;
    color: #f4f4f4;
}

.timeline {
    display: inline-flex;
}

.duration-text {
    padding-left: 5px;
    font-weight: 500;
    color: #E0E0E0;
}

.topic-links {
    padding-top: 30px;
}

.topic-links *{
    margin-right: 15px;
}

.content-description {
    padding-bottom: 20px;
    border-bottom: 2px solid #222222;
    font-size: 16px;
}

.content-description *{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 150%;
}


.content-description li, blockquote{
    padding: initial;
}



@media screen and (min-width: 576px) {
    .swal2-content{
        -webkit-appearance: none;
        padding: 0 !important;
    }

    .modal-container {
        -webkit-appearance: none;
        padding: 2%;
    }

    .topic-header-info {
        padding-left: 20px;
    }

    .thumbnail-info-wrapper {
        flex-direction: row;
    }

    .topic-cover {
        min-height: 200px;
        min-width: 200px;
        padding: 0;

        display: flex;
        justify-content: flex-start;
    }

    .cover-inner {
        height: 250px;
        width: 250px;
    }

    .section-text {
        font-size: 18px;
    }

    /*
- make tagline text bigger but figure it out prperly and not janky
*/

    .section-text, .name-tagline-wrapper, .timeline, .topic-links, .content-description, .tags{
        padding-top: 3%;
    }

    .tagline-text {
        padding-top: 15px;
        font-size: 15px;
        font-weight: 600;
    }

    .name-tagline-wrapper {
        padding-top: 5%;
    }

    .modal-container .tags *{
        font-size: 12px;
    }
    .content-description *{
        padding-top: 10px;
        padding-bottom: 10px;

        font-size: 14px;
        letter-spacing: 0.005em;
        line-height: 150%;
    }

    .content-description p a{
        color: #5ED9CE !important;
    }

    .content-description p a:hover{
        color: #007ea7 !important;
    }


}